.or-separator {
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%; /* Adjust as needed */
    margin: 20px 0; /* Adjust spacing as needed */
  }
  
  .line {
    flex: 1;
    height: 1px;
    background-color: #ccc; /* Adjust color as needed */
  }
  
  .text {
    margin: 0 10px; /* Adjust spacing as needed */
    color: #666; /* Adjust color as needed */
    font-weight: bold;
  }
  